import React, { useContext } from 'react';
import { useQuery } from "@tanstack/react-query";
import { FaBuildingColumns, FaSchool } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

// Fetch organization service
const getOrganizations = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/organizations/all`, {
      method: 'GET',
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`HTTP error! status: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    return data; // Ensure an array is returned
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error; // Re-throw for useQuery
  }
};

const SelectOrganization = ({ setOrganization, nextStep }) => {
  const { t } = useTranslation();
  const { data: organizations, isLoading, isFetching, error } = useQuery({
    queryKey: ['organizations'],
    queryFn: getOrganizations,
    refetchOnWindowFocus: false,
  });

  const handleOrganizationPress = (item) => {
    setOrganization(item.id);
    nextStep();
  };

  if (isLoading || isFetching) {
    return (
        <div className="grid grid-cols-3 bg-white md:grid-cols-4 lg:grid-cols-6 gap-4"> {/* Added bg-white */}
        {Array.from({ length: 9 }).map((_, index) => (
                <div
                key={index}
                className="p-4 flex flex-col items-center justify-center border-2 border-gray-300 bg-white rounded-lg shadow-sm animate-pulse"
              >
                <div className="h-6 w-20 bg-gray-200 rounded-md mb-2"></div>
                <div className="h-4 w-16 bg-gray-200 rounded-md"></div>
              </div>        ))}
      </div>
    );
  }

  if (error instanceof Error) {
    return <p className="text-red-500 text-center mt-4">Error loading organizations: {error.message}</p>;
  }

  return (
    <div>
      <div className="flex flex-col justify-between bg-gray-100 p-2 container w-screen">
        <div className="border rounded-xl bg-white border-gray-200 p-2"> {/* Added bg-white */}
          <h2 className="text-pretty text-sm md:text-lg pb-2 text-gray-600 font-semibold ">{t("select_organization")}</h2> {/* Updated translation key */}
          <div className="grid grid-cols-3 bg-white md:grid-cols-4 lg:grid-cols-6 gap-4"> {/* Added bg-white */}
            {organizations?.map((item) => (
              <div
                key={item.id}
                className={`p-2 flex flex-col md:p-2 border rounded-lg shadow-md transition-all transform hover:scale-105
                   ${item.active ? ' bg-blue-300/5 hover:bg-blue-200 border cursor-pointer' : 'bg-gray-100 text-gray-500 opacity-50 cursor-not-allowed'}
                    ${item.id ? 'border-blue-600' : 'border-gray-300'} `}
                onClick={() => handleOrganizationPress(item)}
              >
                <FaSchool className="w-14 h-14 self-center text-blue-800 " /> {/* Added icon */}
                <div className="items-center space-y-2">
                  <h3 className={`text-sm md:text-lg mt-2 line-clamp-1 text-gray-700 font-semibold text-center`}>
                    {t(item.name)} {/* Used t() for translation */}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectOrganization;
