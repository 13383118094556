import {jwtDecode} from "jwt-decode";
import React, { useContext, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/QeteroHeader";
import "../../index.css";
import SimpleFooter from "../Footer/SimpleFooter";
import MultiForm from "../MultiForm";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/authContext";

function Appointment() {
  const { t } = useTranslation(); // Initialize useTranslation hook for i18n
  const { user, setToken, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Auto-login for Telebirr Super App
  const handleAuthLogin = () => {
    window.handleinitDataCallback = (token) => {
      requestAuthData(token);
    };

    let obj = JSON.stringify({
      functionName: "js_fun_h5GetAccessToken",
      params: {
        appid: "1119216614425609",
        functionCallBackName: "handleinitDataCallback",
      },
    });
    window.consumerapp.evaluate(obj);
  };

  const requestAuthData = (token) => {
    window
      .fetch("https://qetero.com/api/v1/payments/telebirr/autologon", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          authToken: token,
        }),
      })
      .then((res) => res.json())
      .then((resAuth) => {
        if (!resAuth || !resAuth.status) {
          console.error("Invalid authentication response");
          return;
        }

        setToken(resAuth.token);
        localStorage.setItem("token", resAuth.token);

        const decodedData = jwtDecode(resAuth.token);
        setUser(decodedData);
        navigate("/");
      })
      .catch((error) => {
        console.error("Error during authentication:", error);
      });
  };

  // Check token on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      if (window.consumerapp) {
        // If inside the super app, initiate auto-login
        handleAuthLogin();
      } else {
        // Redirect to login if in a browser
        navigate("/login", { state: { from: location } });
      }
    } else {
      try {
        const decodedData = jwtDecode(token);
        setUser(decodedData);
      } catch (error) {
        console.error("Invalid token:", error);
        navigate("/login", { state: { from: location } });
      }
    }
  }, [navigate, location, setUser]);

  return (
    <div>
      <div className="p-2">
        <Header />
        <div className="flex flex-row flex-1 align-middle justify-around px-1 py-1 from-primary to-blue-500 bg-gradient-to-r rounded-xl text-stone-900">
          <div className="flex flex-col ml-2 justify-start text-2xl w-1/2 p-2 md:text-3xl font-semibold text-white">
            <div className="font-bold text-xl md:text-3xl mb-2 text-white">
              {t("selam")} <span>{user?.firstname}</span>
            </div>
            <p className="font-normal text-xs md:text-lg text-gray-300">
              {(window.consumerapp === undefined || window.consumerapp === null)
                ? t(
                    "Welcome_to_Documents_Authentication_and_Registration_Service_(DARS)_at_Qetero.com"
                  )
                : t("welcome_to_qetero")}
            </p>
          </div>
          <div className="flex justify-end w-1/2">
            <img
              src="/Dars-Logo.png"
              alt="Qetero"
              className="w-28 h-28 md:w-32 md:h-32 self-center z-0 contain-content"
            />
          </div>
        </div>
      </div>
      <MultiForm />
      <SimpleFooter />
    </div>
  );
}

export default Appointment;
