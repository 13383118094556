import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const sendOtp = async (mobile) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/sendOTP`, { mobile });
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

const verifyOtp = async ({ mobile, otp }) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/verifyOTP`, { mobile, otp });
        return response.data;
    } catch (error) {
        throw new Error('Invalid OTP.');
    }
};

const BookingForSelection = ({
    onSelect,
    backStep,
    firstname,
    lastname,
    mobile,
    setFirstname,
    setLastname,
    setMobile,
    bookingForSelf,
    setBookingForSelf,
    nextStep
}) => {
    const { t } = useTranslation();
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [error, setError] = useState('');
    const [countdown, setCountdown] = useState(0);
    const otpRefs = useRef([...Array(5)].map(() => React.createRef()));

    const phoneRegex = /^09\d{8}$/;
    const sendOtpMutation = useMutation({
        mutationKey: ['sendOtp', mobile],
        mutationFn: sendOtp,
        onSuccess: () => {
            setOtpSent(true);
            setError('');
            setCountdown(60); 
        },
        onError: (err) => {
            setError(t('Error sending OTP: ') + err.message);
        },
    });
    const verifyOtpMutation = useMutation({
        mutationKey: ['verifyOtp', mobile],
        mutationFn: verifyOtp,
        onSuccess: () => {
            setOtpVerified(true);
            nextStep()
            setError('');
        },
        onError: () => {
            setError(t('Invalid OTP.'));
        },
    });

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const handleSendOtp = () => {
        if (!mobile) {
            setError(t('Please enter a mobile number.'));
            return;
        }

        if (!phoneRegex.test(mobile)) {
            setError(t('Please enter a valid Ethiopian mobile number (e.g., 0912345678).'));
            return;
        }


        const formattedMobile = "251" + mobile.slice(1); // Format to 251912121212
        setOtpVerified(false); 
        sendOtpMutation.mutate(formattedMobile); // Send formatted number to API
    };

    const handleVerifyOtp = () => {
        const otp = otpRefs.current.map((ref) => ref.current.value).join('');
        if (otp.length !== 5) {
            setError(t('Please enter a valid 5-digit OTP.'));
            return;
        }
        verifyOtpMutation.mutate({ mobile, otp });
    };

    const handleNext = () => {
        if (!bookingForSelf && (!firstname || !lastname || !mobile || !otpVerified)) {
            setError(t('Please complete all fields and verify OTP.'));
            return;
        }
        onSelect(bookingForSelf, { firstname, lastname, mobile });
        nextStep();
    };

    const handleOtpChange = (index, value) => {
        if (value.length === 1 && index < otpRefs.current.length - 1) {
            otpRefs.current[index + 1].current.focus();
        }
        otpRefs.current[index].current.value = value;
    };

    return (
        <div className="p-4">
            <div className="flex">
                <label className="flex items-center mr-4">
                    <input
                        type="radio"
                        checked={bookingForSelf}
                        onChange={() => setBookingForSelf(true)}
                        className="form-radio h-5 w-5 text-primary"
                    />
                    <span className="ml-2 text-gray-700">{t('For Myself')}</span>
                </label>
                <label className="flex items-center">
                    <input
                        type="radio"
                        checked={!bookingForSelf}
                        onChange={() => setBookingForSelf(false)}
                        className="form-radio h-5 w-5 text-primary"
                    />
                    <span className="ml-2 text-gray-700">{t('For Others')}</span>
                </label>
            </div>
            {!bookingForSelf && (
                <div className="mt-2">
                    <div className="grid grid-cols-2 gap-4 mt-2">
                        <input
                            type="text"
                            placeholder={t('Firstname')}
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            className="block w-full border border-gray-300 rounded-lg p-2 text-gray-700"
                        />
                        <input
                            type="text"
                            placeholder={t('Lastname')}
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            className="block w-full border border-gray-300 rounded-lg p-2 text-gray-700"
                        />
                    </div>
                    <div className="relative mt-2">
                        <input
                            type="tel"
                            placeholder={t('Mobile Number')}
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            className="block w-full border border-gray-300 rounded-lg p-2 pr-20 text-gray-700"
                        />
                        <button
                            onClick={handleSendOtp}
                            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-blue-500 text-white px-3 py-1 rounded-lg text-sm"
                            disabled={sendOtpMutation.isLoading || countdown > 0}
                        >
                            {countdown > 0 ? `${t('Resend OTP')} (${countdown}s)` : t('Send OTP')}
                        </button>
                    </div>
                    {otpSent && (
                        <div>
                            <div className="flex space-x-2 mt-2">
                                {otpRefs.current.map((ref, index) => (
                                    <input
                                        key={index}
                                        ref={ref}
                                        type="text"
                                        maxLength="1"
                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                        className="block w-12 h-12 text-center border border-gray-300 rounded-lg text-gray-700"
                                    />
                                ))}
                            </div>
                            <button
                                onClick={handleVerifyOtp}
                                className="mt-2 bg-green-500 text-white px-4 py-2 rounded-lg"
                                disabled={verifyOtpMutation.isLoading || otpVerified}
                            >
                                {verifyOtpMutation.isPending ? t('loading') : t('Verify OTP')}
                            </button>
                        </div>
                    )}
                    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                </div>
            )}
            <div className="flex justify-between mt-4">
                <button onClick={backStep} className="bg-gray-400 text-white px-4 py-2 rounded-lg">
                    {t('back')}
                </button>
                <button
                    onClick={handleNext}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                    disabled={!bookingForSelf && !otpVerified}
                >
                    {t('next')}
                </button>
            </div>
        </div>
    );
};

export default BookingForSelection;
