import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { useQuery } from '@tanstack/react-query';
import BranchSelection from './BranchSelection';
import DateSelection from './DateSelection';
import ServiceSelection from './ServiceSelection';
import TimeSlotSelection from './TimeSlotSelection';

import { jwtDecode } from 'jwt-decode';
import ConfirmationModal from './ConfrmationModal';
import BookingForSelection from './BookingForSelection';
import Review from './Review';
import SelectOrganization from './OrganizationSelection';

// const ConfirmationModal = ({
//   selectedBranchName,
//   selectedServiceName,
//   isLoading,
//   selectedDate,
//   selectedTimeSlot,
//   totalFee = "50 ETB",
//   onClose,
//   documentCount,
//   setDocumentCount,
//   onConfirm,
// }) => {
//   const [hasMoreDocuments, setHasMoreDocuments] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");

//   const handleDocumentCountChange = (e) => {
//     const value = parseInt(e.target.value, 10);

//     if (value > 3) {
//       setErrorMessage(i18n.t("For 1 appointment, you can have up to 3 documents."));
//       setDocumentCount(3);
//     } else {
//       setErrorMessage("");
//       setDocumentCount(value);
//     }
//   };

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-2 z-50">
//       <div className="bg-white border border-gray-400 p-6 md:p-12 rounded-lg shadow-2xl w-full max-w-md md:max-w-lg transform transition-all duration-300">
//         <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-8 text-start">{i18n.t('confirm_booking')}</h2>

//         <div className="text-sm md:text-lg text-gray-700">
//           <div className="flex justify-between gap-4 mb-4">
//             <span className="font-normal">{i18n.t('branch')}:</span>
//             <span className="font-semibold">{i18n.t(selectedBranchName)}</span>
//           </div>
//           <div className="flex justify-between gap-4 pb-4 mb-4 border-b border-gray-300">
//             <span className="font-normal">{i18n.t('service')}:</span>
//             <span className="font-semibold">{i18n.t(selectedServiceName)}</span>
//           </div>

//           <div className="flex justify-between gap-4 mb-4">
//             <span className="font-normal">{i18n.t('bookingDate')}:</span>
//             <div className="flex flex-col justify-end items-end">
//               <span className="font-semibold text-xs md:text-sm">{convertToEthiopianDate(selectedDate)} </span>
//               <span className="font-semibold  text-xs md:text-sm">{formatTime(selectedDate)} </span>
//             </div>
//           </div>
//           <div className="flex justify-between gap-4 mb-4">
//             <span className="font-normal">{i18n.t('bookingTime')}</span>
//             <div className="flex flex-col justify-end items-end">
//               <span className="font-semibold text-xs md:text-sm">{convertToEthiopianLocalTime(selectedTimeSlot?.time_from)}{`( ${convertToAmPm(selectedTimeSlot?.time_from)} )`}</span>
//             </div>
//           </div>
//         </div>

//         <div className="mt-8 flex justify-between align-middle bg-blue-100 p-4 rounded-lg text-lg md:text-xl pt-2">
//           <div className="flex flex-col">
//             <p className="text-sm font-normal text-gray-500 ">{i18n.t('payment_summary')}</p>
//             <span className="font-medium text-gray-600">{i18n.t('serviceFee')}:</span>
//           </div>
//           <span className="text-green-600 self-end font-black">{totalFee}</span>
//         </div>
        
//         <div className="mt-4">
//           <label className="flex items-center gap-2">
//             <input
//               type="checkbox"
//               checked={hasMoreDocuments}
//               onChange={(e) => setHasMoreDocuments(e.target.checked)}
//               className="form-checkbox h-5 w-5 text-primary"
//             />
//             <span className="text-sm md:text-lg text-gray-700">{i18n.t('Do you have more than one document?')}</span>
//           </label>

//           {hasMoreDocuments && (
//             <div className="mt-4">
//               <label className="block text-sm font-medium text-gray-700 mb-2">{i18n.t('Number of documents')}</label>
//               <input
//                 type="number"
//                 min="1"
//                 max="3"
//                 value={documentCount}
//                 onChange={handleDocumentCountChange}
//                 className="block w-full border border-gray-300 rounded-lg p-2 text-gray-700 focus:ring-primary focus:border-primary"
//               />
//               {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
//             </div>
//           )}
//         </div>

//         <div className="flex flex-col align-middle text-base justify-start">
//           <img src={'./TeleBirr-Logo.svg'} alt="Telebirr Logo" className="w-24 h-16 contain-content self-center" />
//           <h3 className="text-center text-lg text-gray-800 font-semibold">{i18n.t("pay_with_telebirr")}</h3>
//         </div>

//         <div className="flex justify-around items-center space-x-4 mt-4">
//           <button
//             onClick={onClose}
//             className="bg-gray-300 text-gray-700 text-base md:text-lg py-2 px-5 rounded-lg shadow hover:bg-gray-400 focus:ring-2 focus:ring-gray-300 focus:outline-none transition duration-300"
//           >
//             {i18n.t('cancel')}
//           </button>
//           <button
//             onClick={onConfirm}
//             className="flex items-center bg-primary font-bold border border-primary text-white text-sm md:text-lg py-2 px-5 rounded-lg shadow hover:bg-black-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transition duration-300"
//           >
//             {isLoading ? i18n.t('loading') : i18n.t('confirmAndPay')}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

const fetchLastCheckedoutAppointment = async (token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/reviews/last_appointment`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching last checked-out appointment:", error);
    return null; // Or throw the error, depending on your error handling strategy
  }
};


const Booking = () => {
  const fixedAmount = "50";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceName, setSelectedServiceName] = useState();
  const [selectedServiceOriginal, setSelectedServiceOriginal] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [documentCount, setDocumentCount] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const { user, token,setToken } = useContext(AuthContext);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [mobile, setMobile] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [bookingForSelf, setBookingForSelf] = useState(true);
  const [otherPersonMobile, setOtherPersonMobile] = useState('');
  const announcementMessage = "DARS branches will soon offer an online appointment booking system to streamline visits, reduce wait times, and enhance your experience. Stay tuned for updates!";
  
  const news = "Exciting news!";

  const stepTitles = ['selectBranch', 'selectService', 'selectDate', 'selectTimeSlot','bookingFor'];

  const { data: lastCheckedOutAppointment } = useQuery({
    queryKey: ["lastCheckedOutAppointment", token],
    queryFn: () => fetchLastCheckedoutAppointment(token),
    enabled: !!token, // Only run if token exists
  });


  console.log("appointment", lastCheckedOutAppointment)

  const handleCloseModal = () => {
    setShowModal(false);
    setIsLoading(false);
    navigate('/bookingHistory');
  };


  const nextStep = () => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  
    if (step === 1) {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp < currentTime) {
            localStorage.removeItem('token');
            setToken(null);
            navigate('/Login');
            return;
          } else {
            setStep(2);
            return;
          }
        } catch (error) {
          console.error("Invalid token:", error);
          navigate('/Login');
          return;
        }
      } else {
        // No token, redirect to login
        navigate('/Login');
      }
    }
  
    // Proceed with the remaining steps if the token is valid
    if (step === 2) setStep(3);
    if (step === 3) setStep(4);
    if (step === 4) setStep(5);
    // if (step === 5) setStep(6);
    if (step === 5) setShowModal(true);
  };

  const initiatePayment = async (appointmentId) => {
    try {
      setIsLoading(true);
      //If C2B
      if (window.consumerapp === undefined || window.consumerapp === null) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/payments`, {
          appointment_id: appointmentId,
          type: "service Fee",
          amount: fixedAmount.toString(),
          title: "serviceFee_" + fixedAmount,
          paymentType: "C2B"
        });

        const paymentUrl = response.data.url;
        if (paymentUrl) {
          window.location.href = paymentUrl.trim();
        }


      }
      else { //If Telebirr Superapp 
        window.handleinitDataCallback = function () {
          window.location.href = window.location.origin;
        };
        if (!fixedAmount) {
          return;
        }
        setIsLoading(true);
        window
          .fetch(`${process.env.REACT_APP_API_URL}/v1/payments`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              appointment_id: appointmentId,
              type: "service Fee",
              amount: fixedAmount.toString(),
              title: "serviceFee_" + fixedAmount,
              paymentType: "InApp"
            }),
          })
          .then((res) => {
            res
              .text()
              .then((rawRequest) => {
                //   console.log(rawRequest.trim());
                let obj = JSON.stringify({
                  functionName: "js_fun_start_pay",
                  params: {
                    rawRequest: rawRequest.trim(),
                    functionCallBackName: "handleinitDataCallback",
                  },
                });

                if (typeof rawRequest === undefined || rawRequest === null) return;
                if (window.consumerapp === undefined || window.consumerapp === null) {
                  // console.log("This is not opened in app!");
                  return;
                }
                window.consumerapp.evaluate(obj);
              })
              .catch((error) => {
                console.log("error occur", error);
              })
              .finally(() => { });
          })
          .finally(() => {
            //loading.hide();
            setIsLoading(true);
          });
      }

    } catch (error) {
      console.error("Error initiating payment:", error.message);
    } finally {
      setIsLoading(true);
    }
  };

  const handleBookingForSelect = (forSelf, mobile) => {
    setBookingForSelf(forSelf);
    setOtherPersonMobile(mobile);

};
 

const addAppointmentAndPay = async () => {
    setIsLoading(true);
    const [hours, minutes] = selectedTimeSlot.time_from.split(':');
    const date = new Date(selectedDate);
    date.setHours(hours);
    date.setMinutes(minutes);

    const appointmentData = {
      first_name: firstname,
      last_name: lastname,
      mobile: mobile,
      appointment_time: date.toISOString(),
      document_count: documentCount,
      reschedule_count: 0,
      appointment_through: "self",
      branch_service_id: selectedService,
      appointment_date: date.toISOString().split("T")[0],
      appointment_duration_id: selectedTimeSlot.duration_id,
      created_by: user?.id,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/appointments/${bookingForSelf ? 'add_appointment' : 'add_appointment_for_other'}`,
        appointmentData
      );

      if (response.data?.status === "success") {
        const appointmentId = response?.data?.data?.data?.id;
        await initiatePayment(appointmentId);
      }
    } catch (err) {
      console.error("Error creating appointment:", err);
    } finally {
      setIsLoading(true);
      setShowModal(true);
    }
  };


  return (
    <div className="flex justify-center items-center flex-col">
      <div className="flex pt-4 bg-gray-100 justify-center align-middle self-center">
        {stepTitles.map((title, index) => (
          <div key={index} className="flex items-center mr-4 mb-2">
            <div className={`w-12 h-2 rounded-r-md flex items-center justify-center ${step >= index + 1 ? "bg-pink-600" : "bg-gray-700"}`}></div>
          </div>
        ))}
      </div>
      {
        step === 1 && (
          <SelectOrganization
            setOrganization={setSelectedOrganization}
            nextStep={nextStep}
          />
        )
      }
      {step === 2 && (
        <BranchSelection
          selectedOrganization={selectedOrganization}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          setSelectedBranchName={setSelectedBranchName}
          nextStep={nextStep}
          backStep={() => setStep(1)}
          setSelectedAddress={setSelectedAddress}
        />
      )}
      {step === 3 && (
        <ServiceSelection
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          selectedServiceOriginal={selectedServiceOriginal}
          setSelectedService={setSelectedService}
          setSelectedServiceOriginal={setSelectedServiceOriginal}
          setSelectedServiceName={setSelectedServiceName}
          selectedAddress={selectedAddress}
          nextStep={nextStep}
          backStep={() => setStep(2)}
          selectedBranchName={selectedBranchName}
        />
      )}
      {step === 4 && (
        <DateSelection
          selectedDate={selectedDate}
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          setSelectedDate={setSelectedDate}
          selectedServiceName={selectedServiceName}
          selectedBranchName={selectedBranchName}
          backStep={() => setStep(3)}
          nextStep={nextStep}
        />
      )}
      {step === 5 && (
        <TimeSlotSelection
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          setSelectedTimeSlot={setSelectedTimeSlot}
          selectedServiceName={selectedServiceName}
          selectedBranchName={selectedBranchName}
          backStep={() => setStep(4)}
          nextStep={nextStep}
        />
      )}
    {step === 6 && (
        <BookingForSelection
            onSelect={handleBookingForSelect}
            backStep={() => setStep(4)}
            firstname={firstname}
            lastname={lastname}
            mobile={mobile}
            nextStep={nextStep}
            setFirstname={setFirstname}
            setLastname={setLastname}
            setMobile={setMobile}
            bookingForSelf={bookingForSelf}
            setBookingForSelf={setBookingForSelf}
          />
    )}
      {showModal && (
        <ConfirmationModal
          selectedBranchName={selectedBranchName}
          selectedServiceName={selectedServiceName}
          firstname={firstname}
          lastname={lastname}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          onClose={() => setShowModal(false)}
          onConfirm={addAppointmentAndPay}
          isLoading={isLoading}
          documentCount={documentCount}
          setDocumentCount={setDocumentCount}
        />
      )}
     {lastCheckedOutAppointment && <Review lastCheckedOutAppointment={lastCheckedOutAppointment} /> } 
    </div>
  );
};

export default Booking;
