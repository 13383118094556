import React, { useContext, useState } from 'react';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { AuthContext } from '../../contexts/authContext';

const ReviewModal = ({ lastCheckedOutAppointment }) => {
    console.log("inside review modal",lastCheckedOutAppointment)
    const { t } = useTranslation();
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [isOpen, setIsOpen] = useState(true); // State to control modal visibility
    const {token} = useContext(AuthContext)
    const createReviewMutation = useMutation({
        mutationKey: ['createReview'],
        mutationFn: async () => {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/reviews`, {
                appointment_id: lastCheckedOutAppointment?.lastAppointment?.id,
                rating: rating,
                comments: comment
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
        );
            return response.data;
        },
        onSuccess: () => {
            onClose(); // Close the modal after successful submission
        },
        onError: (error) => {
            console.error("Error submitting Review:", error);
            // Handle error, e.g., display an error message
        }
    });

    const handleSubmit = () => {
        if (rating === 0) {
            // You might want to display a message asking the user to select a rating
            return;
        }
        createReviewMutation.mutate();
    };

    const onClose = () => {
        setIsOpen(false); // Close the modal
    };
    console.log("update review modal",isOpen,lastCheckedOutAppointment)

    if (!isOpen || lastCheckedOutAppointment?.isReviewed) return null;

    return (
        <div className="fixed overflow-hidden inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-50 backdrop-blur-sm">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                <div className="mb-4">
                    <p className="text-gray-600 text-sm">
                        {t('appointment_date')}: {new Date(lastCheckedOutAppointment.lastAppointment.updated_at).toLocaleDateString()}
                    </p>
                </div>

                <h3 className="text-xl font-semibold mb-2">{t("rate_your_experience")}</h3>
                <div className="flex gap-4 mb-4">
                    {[1, 2, 3, 4, 5].map((star) => (
                        <button key={star} onClick={() => setRating(star)}>
                            {star <= rating ? (
                                <StarFilled size={100} className='text-yellow-500' />
                            ) : (
                                <StarOutlined size={100} />
                            )}
                        </button>
                    ))}
                </div>

                <textarea
                    className="w-full border border-gray-300 rounded-lg p-2 mb-4"
                    placeholder={t('leave_a_comment')}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={3}
                />

                <button
                    onClick={handleSubmit}
                    disabled={createReviewMutation.isLoading} // Disable button during submission
                    className={`bg-blue-500 text-white px-4 py-2 rounded-lg ${createReviewMutation.isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {createReviewMutation.isPending ? t('submitting') : t('submit')}
                </button>
            </div>
        </div>
    );
};

export default ReviewModal;